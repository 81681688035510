import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Item from "./Item";

function HeaderList(props) {
  var color = "brown";
  const Themecolor = {
    header1: "#7e6441",
    header2: "#8f724a",
    background_main: "#202020",
  };

  const mainmenu = useState(props.mainmenu);
  const detailmenu = useState(props.detailmenu);
  const [stateActive, setstateActive] = useState(mainmenu[0].id[2]);
  const [stateList,setstateList]=useState(stateActive);

  function setdetail(main, detail, photo, des, name, price, ename) {
    // console.log(main);
    if (main == detail) {
      return (
        <Item
          photo={photo}
          description={des}
          name={name}
          price={price}
          ename={ename}
        />
      );
    }
  }

  function changeActiveMainItem(idActive) {
    setstateActive(idActive);
    setstateList(idActive);
  }

  return (
    <div className="flex flex-col overflow-x-hidden">
      <div className=" h-[180px] md:h-[300px]  head-color">
        <div className="h-full  head-bg flex flex-col items-center border-opacity-10 justify-center">
          <div className="flex flex-row items-center justify-center">
            <div className="w-[480px] mb-3  flex flex-row justify-center items-center">
              <div className="flex  justify-center w-[200px] h-[42px] my-[2px] md:my-[5px] rounded-full ">
                {/* logo */}
                <div className="flex flex-row gap-1 h-fit">
                  <div className="flex flex-col items-center justify-center">
                    <div className="text-[19px] text-white font-bold font-arlrdbd">
                      Cafe Didar
                    </div>
                    <div className="text-[13px] text-white leading-[3px] font-alro400">
                      Caffee shop
                    </div>
                  </div>
                  <div
                    className={`h-[42px] w-[42px] flex mt-2 before:content-[attr(before)]`}
                  >
                    <img
                      className="bg-white bg-opacity-20 h-[42px] rounded-[5px]"
                      src="./image/logo.png"
                      alt="didar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  logo down */}
          <div className="flex flex-row w-[340px] md:w-[480px] justify-between mt-3 items-center">
            <div className="flex justify-center rounded-sm py-2 px-5 items-center border border-1 border-white bg-white bg-opacity-20">
              <span className="text-white text-sm">شیراز-آباده</span>
            </div>
            <div className="flex flex-col justify-center items-end">
              <span className="colorspanmycity">Happiness begins with didar</span>
              <span className="colorspanwellcome">
                !به کافه دیدار خوش آمدید
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={` px-[10px] flex flex-col py-10 `}>
        <div className="h-[200px]  flex justify-center items-center ">
          <div className="grid grid-cols-3 gap-6">
            {Object.keys(mainmenu[0].name).map((keyname, i) => (
              <div
                className={`cursor-pointer border-2 border-[#f0f4f8]  ${
                  mainmenu[0].id[i]
                } 
                  ${
                    mainmenu[0].id[i] == stateActive ? "active" : "false"
                  } bg-[#f0f4f8] rounded-[12px] py-1 px-2`}
                onClick={() => changeActiveMainItem(mainmenu[0].id[i])}
              >
                <div className="h-[50px] w-full flex items-center justify-center ">
                  <img
                    className="flex justify-center items-center"
                    src={
                      "https://www.caffedidar.ir/api_didar/Photos/" +
                      mainmenu[0].photo[i]
                    }
                    alt="category image"
                  />
                </div>
                <span
                  className={`text-[0.75rem] md:text-[0.80rem] md:px-[2px] pt-[1px] flex justify-center items-center text-[#0c498a] font-[450] text-center text-${mainmenu[0].id[i]} `}
                >
                  {mainmenu[0].name[i]}
                </span>
              </div>
            ))}
          </div>
        </div>
        {/* <div>
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          className="flex justify-center items-center w-full my-4"
        >
          {/* <div className="h-5/6 flex flex-row text-center" > */}

        {/* {Object.keys(mainmenu[0].name).map((keyname, i) => (
            <SwiperSlide
              onClick={() => scrollToElement(mainmenu[0].id[i])}
              className="flex text-center cursor-pointer self-center items-center justify-center"
            >
              <div className="flex items-center justify-center">
                <div className="h-[100px] w-fit flow-root flex-col flex-wrap items-center justify-center bg-white bg-opacity-30 rounded-2xl px-5 pt-2">
                  <div className="h-[50px] w-[50px] flex items-center justify-center">
                    <img
                      src={
                        "https://www.caffedidar.ir/api_didar/Photos/" +
                        mainmenu[0].photo[i]
                      }
                      alt=""
                    />
                  </div>
                  <div className="text-[13px] pt-1">{mainmenu[0].name[i]}</div>
                </div>
              </div>
            </SwiperSlide>
          ))} */}

        {/* </div> */}
        {/* </Swiper> */}
        {/* </div> */}
      </div>
      <div className="flex justify-center">
        <div className=" overflow-y-auto md:w-1/2">
          {Object.keys(mainmenu[0].name).map((keyname, j) => (
            <div className={`flex flex-col p-4 ${stateList==mainmenu[0].id[j] ? "block":"hidden"}`} id={mainmenu[0].id[j]}>
              <div className="flex justify-center items-center font-semibold text-[19px] mb-5">
                <div className="flex justify-center w-full">
                  <span className="text-[#0c498a] px-1 flex items-center gap-2 text-[1rem] font-[700]">
                    <svg
                      width="15"
                      height="4"
                      viewBox="0 0 15 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.395908 2.08195L1.98633 0.86967L12.3559 0.894463L14.3773 2.09807L12.5942 3.17911L2.24917 3.21433L0.395908 2.08195Z"
                        fill="#FFCA30"
                      ></path>
                    </svg>
                    <span>{mainmenu[0].name[j]}</span>
                    <svg
                      width="15"
                      height="4"
                      viewBox="0 0 15 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.395908 2.08195L1.98633 0.86967L12.3559 0.894463L14.3773 2.09807L12.5942 3.17911L2.24917 3.21433L0.395908 2.08195Z"
                        fill="#FFCA30"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 ">
                {Object.keys(detailmenu[0].name).map((keyname, i) =>
                  setdetail(
                    mainmenu[0].name[j],
                    detailmenu[0].Title[i],
                    detailmenu[0].photo[i],
                    detailmenu[0].Description[i],
                    detailmenu[0].name[i],
                    detailmenu[0].Price[i],
                    detailmenu[0].Ename[i]
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default HeaderList;
