import React, { Component} from "react";
import Itemproduct from "./Itemproduct";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Product extends Component{
    
    
    

    constructor(props){
        super(props);
        this.state={
            product:[],
            isLoaded:false,
            isLoadedcategory:false,
            insertdialog:false,
            listcategory:[],
            inputs:{},
            name:"",
            ename:"",
            price:"",
            description:"",
            category:"",
            photo:'',


        }

        this.handleChangename = this.handleChangename.bind(this);
        this.handleChangecategory = this.handleChangecategory.bind(this);
        this.handleChangedescription = this.handleChangedescription.bind(this);
        this.handleChangeename = this.handleChangeename.bind(this);
        this.handleChangephoto = this.handleChangephoto.bind(this);
        this.handleChangeprice = this.handleChangeprice.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChangename(event){
       this.setState({name:event.target.value});

        
    }
    
    handleChangeename(event){
        this.setState({ename:event.target.value});

        
    }
    
    handleChangeprice(event){
        this.setState({price:event.target.value});

        
    }
    handleChangedescription(event){
        this.setState({description:event.target.value});

    }
    handleChangecategory(event){
    
        this.setState({category:event.target.value});

        
    }
    
    handleChangephoto(event){
        const file=event.target.files[0];
        this.setState({photo:file});


        
    }

    handleSubmit = async(event)=>{
        event.preventDefault();

        let data =new FormData();

        const name=this.state.name;
        const ename=this.state.ename;
        const price=this.state.price;
        const des=this.state.description;
        const category=this.state.category;
        const photo=this.state.photo;
        

        
        data.append('image',photo);
        data.append("Name",name);
        data.append("Ename",ename);
        data.append("Price",price);
        data.append("Description",des);
        data.append("Tid",category);

        // let data={Name:name,Ename:ename,Price:price,Description:des,Tid:category,image:photo};
        // data=JSON.stringify(data);
        

        // console.log(photo)

        console.log(data.get('image'));

        // fetch("https://www.caffedidar.ir/api_didar/set_food2.php", {
        // method: "POST",
        // mode:'cors',
        // headers:{
        //     'Content-Type':"application/json",
        //     'Access-Control-Allow-Origin':'http://localhost:3000,http://127.0.0.1:3000',
        // },
        // body: {Name:name,Ename:ename,Price:price,Description:des,Tid:category,image:photo},
        // })
        // .then(function(response){ 
        //     console.log(response);
        // // return response.json();   
        // })
        // .then(function(data){ 
        // console.log(data)
        // }); 
     
        try {
            // const res = await axios.post('https://www.caffedidar.ir/api_didar/set_food2.php'
            const res = await axios.post('./api_didar/set_food2.php'

            ,{Name:name,Ename:ename,Price:price,Description:des,Tid:category,image:photo},{
                
                mode:'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data;text/html; charset=utf-8',
                  }
                  ,
            withCredentials: false,
            credentials: 'same-origin',
            
            })
            // const res = await axios.post('./api_didar/set_food2.php',{mode:'cors'},{Name:name,Ename:ename,Price:price,Description:des,Tid:category,image:photo})
            if(res.status == '200')
            {
                toast.success("محصول با موفقیت اضافه شد", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
                    window.location.reload(false);
            }
        } catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            console.log(error)
        }

        // axios.post('https://www.caffedidar.ir/api_didar/set_food2.php',{mode:'cors'}, data)
        //   .then((response) => {
        //     console.log(response);
        //   }, (error) => {
        //     console.log(error);
        //   });

        
    }
   

    componentDidMount(){
        // fetch('./api_didar/list_Food.php')
        fetch('https://www.caffedidar.ir/api_didar/list_Food.php')
        .then(res => res.json())
        .then(json =>{
            this.setState({
                isLoaded:true,
                product:json,
            })
        })
        //  fetch('./api_didar/list_Food.php')
         fetch('https://www.caffedidar.ir/api_didar/list_Title.php')
         .then(res => res.json())
         .then(json =>{
             this.setState({
                 listcategory:json,
                 isLoadedcategory:true
                 
             })
         })
    }
   
       
   sconsole(event){
    event.preventDefault();
    console.log(event)
}


    render(){
        const isLoadedcategory = this.state.isLoadedcategory;

    
    
        var {isLoaded,product}=this.state;
        if(!isLoaded || product.length == 0){
            <div className="flex flex-col w-screen h-screen justify-center items-center">
            <img src="./image/loading.gif" alt="" />
            <div>
                <span>Loading ...</span>
            </div>

        </div>
        }
        else{

    return(
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                />
            <section class="antialiased bg-gray-100 text-gray-600 h-screen " dir="rtl">
                <div class="flex flex-col h-full">
                    <div class="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
                        <header class=" flex flex-row px-5 py-4 border-b border-gray-100">
                            <h2 class="font-semibold text-gray-800">محصولات</h2>
                            <button className="mr-3 bg-blue-300 rounded-lg" onClick={()=>this.setState({insertdialog:true})}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/> </svg></button>
                        </header>
                        <div class="p-3">
                            <div class="overflow-x-auto">
                                <table class="table-auto w-full">
                                    <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                        <tr>
                                            <th class="p-2 whitespace-nowrap">
                                                <div class="font-semibold text-left">عکس</div>
                                            </th>
                                            <th class="p-2 whitespace-nowrap">
                                                <div class="font-semibold text-left">اسم</div>
                                            </th>
                                            <th class="p-2 whitespace-nowrap">
                                                <div class="font-semibold text-left">اسم انگلیسی</div>
                                            </th>
                                            <th class="p-2 whitespace-nowrap">
                                                <div class="font-semibold text-center">قیمت</div>
                                            </th>
                                            
                                            <th class="p-2 whitespace-nowrap">
                                                <div class="font-semibold text-center">دسته بندی</div>
                                            </th>
                                            <th class="p-2 whitespace-nowrap">
                                                <div class="font-semibold text-center">توضیحات </div>
                                            </th>
                                            <th class="p-2 whitespace-nowrap">
                                              
                                                   بیشتر
                                                
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody class="text-sm divide-y divide-gray-100">
                                    
                                        <Itemproduct product={this.state.product} category={this.state.listcategory} />
                                    
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

                           {/* <!--modal content--> */}
                           <div
                        class={`${this.state.insertdialog ? 'fixed' : 'hidden' } overflow-scroll z-55 inset-4 top-20 mx-auto p-5 border w-92 shadow-lg rounded-md bg-white`}
                    >
                        <svg width="45" height="45" className="fixed right-0 mr-5" onClick={()=>this.setState({insertdialog:false})}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M14 14L34 34" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 34L34 14" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <div class="mt-3 text-center">
                            
                        <form method="post" onSubmit={this.handleSubmit} class="bg-white  rounded px-8 pt-6 pb-8 mb-4">
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                                    اسم
                                </label>
                                <input onChange={this.handleChangename} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="Name" id="Name" type="text" placeholder="" required/>
                                </div>
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                    اسم انگلیسی
                                </label>
                                <input onChange={this.handleChangeename} class="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="Ename" id="Ename" type="text" required/>
                                </div>
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                    قیمت
                                </label>
                                <input onChange={this.handleChangeprice} class="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="Price" id="Price" type="text" required/>
                                </div>
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                                    دسته بندی
                                </label>
                                <select value={this.state.category}  onChange={this.handleChangecategory}    className="rounded-lg">
                                    <option value="انتخاب">انتخاب</option>
                                
                                    {isLoadedcategory?
                                        Object.keys(this.state.listcategory.name).map((keyname,i)=>(
                                                                            
                                                                            
                                            <option name="Tid" id={this.state.listcategory.id[i]}  value={this.state.listcategory.id[i]}>{this.state.listcategory.name[i]}</option>
                                            ))
                                            :''
                                    }
                                
                                    
                                    
                                </select>
                                </div>
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="">
                                    توضیحات
                                </label>
                                <input onChange={this.handleChangedescription} class="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="Description" id="Description" type="text" required/>
                                </div>
                                <div class="mb-6">
                                <label  class="block text-gray-700 text-sm font-bold mb-2" for="">
                                    عکس
                                </label>
                                <input onChange={this.handleChangephoto} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="Photo" id="Photo" type="file" required/>
                                </div>
                            <div class="flex items-center justify-between">
                            <button class=" hover:bg-blue-700 text-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={()=>this.setState({insertdialog:false})} > 
                                کنسل
                            </button>
                            <button  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                ثبت محصول
                            </button>
                           
                            </div>
                        </form>
                           
                        </div>
                    </div>
            
            
        </div>
    )   
    }
    }
}

export default Product;