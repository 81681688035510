import React, { useState } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Modal(props) {
  const user='abrarvancloud';
  const pass='best1234qwer';
  const [username,setusername]=useState('');

  const[password,setpassword]=useState('');
  const [login,setlogin]=useState(false);



  function handlogin(e){
    e.preventDefault();
    console.log(username+password);

    if((username==user)&&(password==pass)){

      toast.success("موفق باشید", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        sessionStorage.setItem('key','1');
        setlogin(true)

    }
    else{
      toast.error("نام کاربری یا رمز عبور اشتباه است", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        sessionStorage.setItem('key','-1');
      
    }

  }
  return (
    <div className={`${login ? 'hidden' :'fixed'} inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center`}>
      <div className="bg-white p-2 rounded w-72">
        <h1 className="font-semibold text-center text-xl text-gray-700">
          Wellcome Back
        </h1>
        <p className="text-center text-gray-700 mb-5">Login</p>

        <form onSubmit={(e)=>handlogin(e)}>
        <div className="flex flex-col">

          <input
            type="text"
            className="border border-gray-700 p-2 rounded mb-5"
            placeholder="email@example.com"
            onChange={(e)=>setusername(e.target.value)}
            required
          />
          <input
            type="text"
            className="border border-gray-700 p-2 rounded mb-5"
            placeholder="********"
            onChange={(e)=>setpassword(e.target.value)}
            required
          />
        </div>
        <div className="text-center">
          <button className="px-5 py-2 bg-gray-700 hover:bg-green-400 text-white rounded">
            join
          </button>
        </div>
        </form>

      </div>
    </div>
  );
}