export default function Dialog({ open, onclose, children }) {
  return (
    <div
      onClick={onclose}
      className={`
        fixed inset-0  flex justify-center w-[calc(100%-50px)]  items-center transition-colors z-50   overflow-y-auto pb-2  
        ${open ? "block bg-black/40" : "hidden"}
        `}
    >
      <div
        onClick={(e)=>e.stopPropagation()}
        className={`bg-white rounded-xl shadow transition-all  ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      > 

        {children}

      </div>
    </div>
  );
}

