import React, { Component ,useState } from "react";
import Itemcategory from "./Itemcategory";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class Category extends Component{




    constructor(props){
        super(props);
        
        this.state={
            category:[],
            isLoaded:false,
            dialog:false,
            namecategory:'',
            photocategory:'',
            
        }

    }
    
     handlesub=async(e)=>{
        e.preventDefault();
        // console.log(this.state.photocategory);

        try {
            // const res = await axios.post('https://www.caffedidar.ir/api_didar/set_food2.php'
            const res = await axios.post('./api_didar/set_Titel.php'

            ,{Name:this.state.namecategory,Photo:this.state.photocategory},{
                
                mode:'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data;text/html; charset=utf-8',
                  }
                  ,
            withCredentials: false,
            credentials: 'same-origin',
            
            })
            console.log(res);
            // const res = await axios.post('./api_didar/set_food2.php',{mode:'cors'},{Name:name,Ename:ename,Price:price,Description:des,Tid:category,image:photo})
            if(res.status == '200')
            {
                toast.success("دسته بندی با موفقیت اضافه شد", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
                    window.location.reload(false);
            }
        } catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            console.log(error)
        }

        
    }
   

   


    

    componentDidMount(){
        // fetch('./api_didar/list_Food.php')
        fetch('https://www.caffedidar.ir/api_didar/list_Title.php')
        .then(res => res.json())
        .then(json =>{
            this.setState({
                isLoaded:true,
                category:json,
                
            })
        })
    }

    render(){
        var {isLoaded,category}=this.state;
      
        if(!isLoaded || category.length == 0){
            <div className="flex flex-col w-screen h-screen justify-center items-center">
            <img src="./image/loading.gif" alt="" />
            <div>
                <span>Loading ...</span>
            </div>

        </div>
        }
        else{

    return(
        <div>
            <section class="antialiased bg-gray-100 text-gray-600 h-screen " dir="rtl">
                <div class="flex flex-col h-full">
                    <div class="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
                        <header class=" flex flex-row px-5 py-4 border-b border-gray-100">
                            <h2 class="font-semibold text-gray-800">دسته بندی ها</h2>
                            <button className="mr-3 bg-blue-300  rounded-lg" onClick={()=>this.setState({dialog:true})}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/> </svg></button>
                        </header>
                        <div class="p-3">
                            <div class="overflow-x-auto">
                                <table class="table-auto w-full">
                                    <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                        <tr>
                                            <th class="p-2 whitespace-nowrap">
                                                <div class="font-semibold ">عکس</div>
                                            </th>
                                            <th class="p-2 whitespace-nowrap text-center">
                                                <div class="font-semibold ">اسم</div>
                                            </th>
                                            <th class="p-2 whitespace-nowrap text-center">
                                              
                                            <div class="font-semibold">بیشتر</div>
                                           
                                       </th>
                                          
                                      
                                        </tr>
                                    </thead>
                                    <tbody class="text-sm divide-y divide-gray-100">
                                    
                                        <Itemcategory category={this.state.category}  />
                                    
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                  {/* <!--modal content--> */}
                  <div
                        class={`${this.state.dialog ? 'fixed' : 'hidden' } overflow-scroll z-55 inset-4 top-20 mx-auto p-5 border w-92 shadow-lg rounded-md bg-white`}
                    >
                        <svg width="45" height="45" className="fixed right-0 mr-5" onClick={()=>this.setState({dialog:false})}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M14 14L34 34" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 34L34 14" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <div class="mt-3 text-center">
                            
                        <form method="post" onSubmit={(e)=>this.handlesub(e)} class="bg-white  rounded px-8 pt-6 pb-8 mb-4">
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="itemName">
                                    اسم
                                </label>
                                <input onChange={(e)=>this.setState({namecategory : e.target.value})}  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="itemName" id="itemName" type="text" placeholder="" required/>
                                </div>
                                
                                <div class="mb-6">
                                <label  class="block text-gray-700 text-sm font-bold mb-2" for="">
                                    عکس
                                </label>
                                <input onChange={(e)=>this.setState({photocategory:e.target.files[0]})} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="Photo" id="Photo" type="file" required/>
                                </div>
                                
                               
                            <div class="flex items-center justify-between">
                            <button class=" hover:bg-blue-700 text-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={()=>this.setState({dialog:false})} > 
                                کنسل
                            </button>
                            <button  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                               ثبت دسته بندی 
                            </button>
                           
                            </div>
                        </form>
                           
                        </div>
                 </div>
            </section>
                 
        </div>
    )   
    }
    }
}
export default Category;