import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import {BiEdit} from "react-icons/bi";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const Itemcategory=(props)=>{
    const category=props.category;
    const [namecat,setnamecat]=useState('');
    const [idcat,setcat]=useState('');
    const [dialogcat,setdialogcat]=useState(false);

    const editcat=(id,name)=>{
        setdialogcat(true);
        setnamecat(name);
        setcat(id);

    }

    const handleitemcatSubmit = async(event)=>{
        event.preventDefault();

        
        try {
            // const res = await axios.post('https://www.caffedidar.ir/api_didar/DeleteFood.php'
            const res = await axios.post('./api_didar/updateTitel.php',JSON.stringify({
                idcat:idcat,name: namecat
             }),
            {
            
                mode:'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json; charset=utf-8',
                  }
                  ,
            withCredentials: false,
            credentials: 'same-origin',
            
            })
            if(res.status == '200')
            console.log(res);
            {
                toast.success("دسته بندی ویرایش شد", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
                    window.location.reload(false);
            }
        } catch (error) {
            
            toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            console.log(error)
        }

    }

    const deletecat=async(id)=>{
           // id=JSON.stringify(id);
           console.log(id);
        


           try {
               // const res = await axios.post('https://www.caffedidar.ir/api_didar/DeleteFood.php'
               const res = await axios.post('./api_didar/DeleteTitle.php',JSON.stringify({
                   id: id,
                }),
               {
               
                   mode:'no-cors',
                   headers: {
                       'Access-Control-Allow-Origin': '*',
                       'Content-Type': 'application/json; charset=utf-8',
                     }
                     ,
               withCredentials: false,
               credentials: 'same-origin',
               
               })
               if(res.status == '200')
               console.log(res);
               {
                   toast.success("دسته بندی حذف شد", {
                       position: "top-right",
                       autoClose: 5000,
                       hideProgressBar: false,
                       closeOnClick: true,
                       pauseOnHover: true,
                       draggable: true,
                       progress: undefined,
                       theme: "colored",
                       });
                       window.location.reload(false);
               }
           } catch (error) {
               
               toast.error(error.message, {
                   position: "top-right",
                   autoClose: 5000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                   theme: "colored",
                   });
               console.log(error)
           }
    }

    

    return(
        <>
        {Object.keys(category.name).map((keyName, i) => (  

        <tr>
        <td class="p-2 whitespace">
            <div class="flex items-center">
                <div class="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3"><img class="rounded-full h-[40px]" src={"https://www.caffedidar.ir/api_didar/Photos/"+category.photo[i]} width="40" height="40" alt={category.name[i]}/></div>
                {/* <div class="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3"><img class="rounded-full h-[40px]" src={"./api_didar/Photos/"+category.photo[i]} width="40" height="40" alt={category.name[i]}/></div> */}

            </div>
        </td>
        <td class="p-2 whitespace-nowrap ">
            <div class="text-right flex justify-center">{category.name[i]}</div>
        </td>

        <td className="p-2 whitespace-nowrap flex gap-3">
            <BiEdit size="17px" className="cursor-pointer" color="green" onClick={()=>editcat(category.id[i],category.name[i])} />
            <MdDelete size="17px" className="cursor-pointer" color="red" onClick={()=>deletecat(category.id[i])}/>
        </td>
       
        
            </tr>
             ))}  

 {/* <!--modal content--> */}
 <div
                        class={`${dialogcat ? 'fixed' : 'hidden' } overflow-scroll z-55 inset-4 top-20 mx-auto p-5 border w-92 shadow-lg rounded-md bg-white`}
                    >
                        <svg width="45" height="45" className="fixed right-0 mr-5" onClick={()=>setdialogcat(false)}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M14 14L34 34" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 34L34 14" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <div class="mt-3 text-center">
                            
                        <form method="post" onSubmit={(e)=>handleitemcatSubmit(e)} class="bg-white  rounded px-8 pt-6 pb-8 mb-4">
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="itemName">
                                    اسم
                                </label>
                                <input onChange={(e)=>setnamecat(e.target.value)} value={namecat} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="itemName" id="itemName" type="text" placeholder="" required/>
                                </div>
                        
                                
                               
                            <div class="flex items-center justify-between">
                            <button class=" hover:bg-blue-700 text-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={()=>setdialogcat(false)} > 
                                کنسل
                            </button>
                            <button  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                ویرایش
                            </button>
                           
                            </div>
                        </form>
                           
                        </div>
                 </div>
            

         
             </>
             )

}

export default Itemcategory;