import React, { Component } from "react";
import HeaderList from "./HeaderList";
import Modal from "./Modal";

class Main extends Component{

    constructor(props){
        super(props);
        this.state={
            detailmenu:[],
            mainmenu:[],
            isLoaded_detail:false,
            isLoaded_menu:false,

        }
        

    }

    componentDidMount(){
        // fetch('./api_didar/list_Food.php')
        fetch('https://www.caffedidar.ir/api_didar/list_Food.php')

        .then(res => res.json())
        .then(json =>{
            this.setState({
                isLoaded_detail:true,
                detailmenu:json,
            })
        })
        
        // fetch('./api_didar/list_Title.php')
        fetch('https://www.caffedidar.ir/api_didar/list_Title.php')

        .then(res => res.json())
        .then(json =>{
            this.setState({
                isLoaded_menu:true,
                mainmenu:json,
            })
        })

    }


    render(){   
        
        var {isLoaded_menu,mainmenu}=this.state;
        var {isLoaded_detail,detailmenu}=this.state;

        
        if((!isLoaded_detail || !isLoaded_menu)&&(mainmenu.length==0 || detailmenu.length==0)){
            return(
                <div className="flex flex-col w-screen h-screen justify-center items-center">
                    <img src="./image/logo.png" className="w-32" alt="" />
                    <div>
                        <span>happiness begins with DIDAR</span>
                    </div>

                </div>
            )
        }
        else{
            return(

                <div>
                    
                   

                    <div className=" flex flex-col  font-iransansnew bg-[#fafafa]">
                            <HeaderList mainmenu={this.state.mainmenu} detailmenu={this.state.detailmenu} />
                        
                    </div>
                    
                </div>
                
            )
        }
 
    }
}

export default Main;