import React, { Component, useEffect } from "react";
import { useState } from "react";
import Category from "./category/Category";
import Product from "./product/Product";
import Modal from "../Menu/Modal";



function  Adminmain (){
    const [dropmenu,setdropmenu]=useState(false);
    const [category,setcategory]=useState(false);
    const [product,setproduct]=useState(true);

    const changeStatus=()=>{
        if(dropmenu == false)
        setdropmenu(true);
        else
        setdropmenu(false);
    }
    const componentcategory=()=>{
        setcategory(true);
        setproduct(false);
        setdropmenu(false);
    }
    const componentproduct=()=>{
        setcategory(false);
        setproduct(true);
        setdropmenu(false);
    }

    

    


   
       

    return(
      

        <div>
            {
                sessionStorage.getItem('key')=='-1'||sessionStorage.getItem('key')==null?
                <Modal />
                :''

            }
            
                 
            <body className="bg-blue-500">
                <nav className="relative px-4 py-4 flex justify-between items-center bg-white">
                    <a className="text-3xl font-bold leading-none" href="caffedidar.ir">
                    <img src="./image/logo.png" className="h-10" alt="logo" />

                    </a>
                    <div className="lg:hidden">
                    <button className="navbar-burger flex items-center text-blue-600 p-3" onClick={changeStatus}>
                        <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <title>Mobile menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                        </svg>
                    </button>
                    </div>
                    <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-6">
                    <li><a className={`text-sm cursor-pointer ${category? 'text-blue-600  hover:text-gray-400 ' : 'text-gray-400  hover:text-blue-600 '} `} onClick={componentcategory}>Category</a></li>
                    <li className="text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </li>
                    <li><a className={`text-sm cursor-pointer ${product? 'text-blue-600  hover:text-gray-400 ' : 'text-gray-400  hover:text-blue-600 '}`} onClick={componentproduct}>Product</a></li>
                    <li className="text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                        </svg>
                    </li>
                   
                    </ul>
                 </nav>
                <div className={`navbar-menu relative z-50  ${dropmenu ? '' : 'hidden'}  `}>
                    <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
                    <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
                    <div className="flex items-center mb-8">
                        <a className="mr-auto text-3xl font-bold leading-none" href="caffedidar.ir">
                        <img src="./image/logo.png" className="h-12" alt="logo" />
                        </a>
                        <button className={`navbar-close ${dropmenu ? '' : 'hidden'} `} onClick={changeStatus}>
                        <svg className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                        </button>
                    </div>
                    <div>
                        <ul>
                        <li className="mb-1">
                            <a className={`block p-4 text-sm font-semibold cursor-pointer text-gray-400 hover:bg-blue-50 hover:text-blue-600  rounded`} onClick={componentcategory}>Category</a>
                        </li>
                        <li className="mb-1">
                            <a className="block p-4 text-sm font-semibold cursor-pointer text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded" onClick={componentproduct}>Product</a>
                        </li>
                        </ul>
                    </div>
                    <div className="mt-auto">
                        
                        <p className="my-4 text-xs text-center text-gray-400">
                        <span>Ahmad khodadadegi © 2023</span>
                        </p>
                    </div>
                    </nav>
                </div>
            </body> 
            <div className="m-5">
                <div className={`${category ? '' : 'hidden'} `}>
                    <Category  />
                </div>
                <div className={`${product ? '' : 'hidden'} `}>
                    <Product />
                </div>
            </div>

                 
        </div>  
        
    )

    }
export default Adminmain;