import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import {BiEdit} from "react-icons/bi";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const Itemproduct=(props)=>{
    const itemproduct=props.product;
    const listcategory=props.category;




    const [itemcategory,setcategory]=useState("") //for id category 

    const [update,setupdate]=useState(false);
    const [name,setname]=useState('');
    const[idproduct,setidproduct]=useState('');
    const [ename,setitemename]=useState('');
    const [price,setitemprice]=useState('');
    const [title,setitemtitle]=useState('');
    const [des,setdes]=useState('');





    const handleitemSubmit = async(event)=>{
        event.preventDefault();
        console.log(itemcategory);
        if(itemcategory==''){
            toast.error('لطفا یک دسته بندی انتخاب کنید', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
            return '';
        }


        try {
            // const res = await axios.post('https://www.caffedidar.ir/api_didar/DeleteFood.php'
            const res = await axios.post('./api_didar/updateFood.php',JSON.stringify({
                name: name,Ename:ename,Price:price,Description:des,idproduct:idproduct,idftitle:itemcategory
             }),
            {
            
                mode:'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json; charset=utf-8',
                  }
                  ,
            withCredentials: false,
            credentials: 'same-origin',
            
            })
            if(res.status == '200')
            console.log(res);
            {
                toast.success("محصول ویرایش شد", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
                    window.location.reload(false);
            }
        } catch (error) {
            
            toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            console.log(error)
        }


    }
    const setitemcategory=(e)=>{
        setcategory(e.target.value);
    }

    const edititem=(id,itemname,itemename,itemprice,itemtitle,description)=>{
        setname(itemname);
        setitemename(itemename);
        setitemprice(itemprice);
        setitemtitle(itemtitle);
        setdes(description);
        setitemtitle(itemtitle);
        setidproduct(id)
        setupdate(true);
        


    }
    


    const deleteitem=async(id)=>{
        // id=JSON.stringify(id);
        console.log(id);
        


        try {
            // const res = await axios.post('https://www.caffedidar.ir/api_didar/DeleteFood.php'
            const res = await axios.post('./api_didar/DeleteFood.php',JSON.stringify({
                id: id,
             }),
            {
            
                mode:'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json; charset=utf-8',
                  }
                  ,
            withCredentials: false,
            credentials: 'same-origin',
            
            })
            if(res.status == '200')
            console.log(res);
            {
                toast.success("محصول حذف شد", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
                    window.location.reload(false);
            }
        } catch (error) {
            
            toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
            console.log(error)
        }
    }

    return(
        <>
        {Object.keys(itemproduct.name).map((keyName, i) => (  

        <tr>
        <td class="p-2 whitespace">
            <div class="flex items-center">
                <div class="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3"><img class="rounded-full h-[40px]" src={"https://www.caffedidar.ir/api_didar/Photos/"+itemproduct.photo[i]} width="40" height="40" alt={itemproduct.name[i]}/></div>
                {/* <div class="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3"><img class="rounded-full h-[40px]" src={"./api_didar/Photos/"+product.photo[i]} width="40" height="40" alt={product.itemname[i]}/></div> */}

            </div>
        </td>
        <td class="p-2 whitespace-nowrap">
            <div class="text-right">{itemproduct.name[i]}</div>
        </td>
        <td class="p-2 whitespace-nowrap">
            <div class="text-right font-medium text-green-500">{itemproduct.Ename[i]}</div>
        </td>
        <td class="p-2 whitespace-nowrap">
            <div class="text-right font-medium text-green-500">{itemproduct.Price[i]}</div>
        </td>
        <td class="p-2 whitespace-nowrap">
            <div class="text-medium text-right">{itemproduct.Title[i]}</div>
        </td>
        <td class="p-2 whitespace-nowrap">
            <div class="text-medium text-right">{itemproduct.Description[i]}</div>
        </td>
        <td className="p-2 whitespace-nowrap flex flex-center gap-3">
            <BiEdit size="17px" className="cursor-pointer" color="green" onClick={()=>edititem(itemproduct.id[i],itemproduct.name[i],itemproduct.Ename[i],itemproduct.Price[i],itemproduct.Title[i],itemproduct.Description[i])} />
            <MdDelete size="17px" className="cursor-pointer" color="red" onClick={()=>deleteitem(itemproduct.id[i])}/>
        </td>
            </tr>
             ))}  



                 {/* <!--modal content--> */}
                 <div
                        class={`${update ? 'fixed' : 'hidden' } overflow-scroll z-55 inset-4 top-20 mx-auto p-5 border w-92 shadow-lg rounded-md bg-white`}
                    >
                        <svg width="45" height="45" className="fixed right-0 mr-5" onClick={()=>setupdate(false)}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M14 14L34 34" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 34L34 14" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <div class="mt-3 text-center">
                            
                        <form method="post" onSubmit={(e)=>handleitemSubmit(e)} class="bg-white  rounded px-8 pt-6 pb-8 mb-4">
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="itemName">
                                    اسم
                                </label>
                                <input onChange={(e)=>setname(e.target.value)} value={name} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="itemName" id="itemName" type="text" placeholder="" required/>
                                </div>
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="itemEname">
                                    اسم انگلیسی
                                </label>
                                <input onChange={(e)=>setitemename(e.target.value)} value={ename} class="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="itemEname" id="itemEname" type="text" required/>
                                </div>
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="itemPrice">
                                    قیمت
                                </label>
                                <input onChange={(e)=>setitemprice(e.target.value)} value={price} class="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" name="itemPrice" id="itemPrice" type="text" required/>
                                </div>
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="category">
                                    دسته بندی
                                </label>
                                <select onChange={(e)=>setitemcategory(e)}  className="rounded-lg">
                                <option value="">انتخاب</option>
                                
                                
                                
                                    {listcategory!=''?
                                        Object.keys(listcategory.name).map((keyname,i)=>(
                                            // listcategory.name[i]==title ?
                                            // <option name="itemTid" selected="selected" id={listcategory.id[i]}  value={listcategory.id[i]}>{listcategory.name[i]}</option>
                                            
                                            // :
                                                                                                           
                                            <option name="itemTid" id={listcategory.id[i]}  value={listcategory.id[i]}>{listcategory.name[i]}</option>
                                            ))
                                            :''
                                    }
                                
                                    
                                    
                                </select>
                                </div>
                                <div class="mb-6">
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="itemDescription">
                                    توضیحات
                                </label>
                                <input onChange={(e)=>setdes(e.target.value)} value={des} class="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" itemname="itemDescription" id="itemDescription" type="text" required/>
                                </div>
                                
                               
                            <div class="flex items-center justify-between">
                            <button class=" hover:bg-blue-700 text-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={()=>setupdate(false)} > 
                                کنسل
                            </button>
                            <button  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                ویرایش
                            </button>
                           
                            </div>
                        </form>
                           
                        </div>
                 </div>
            
            
             </>
             )

}


export default Itemproduct;