import React from "react";
import Main from "./components/Menu/Main";
import Adminmain from "./components/Admin/Adminmain";
import Login from "./components/Login";
import { Routes,Route, useRoutes } from "react-router-dom";
import routes from "./router";

const App = () => {
    return (
       <>
          <Routes>
             <Route path="/" element={<Main />} />
             <Route path="/abrarvancloud" element={<Adminmain />} />
             {/* <Route path="/login" element={<Login />} /> */}
          </Routes>
       </>
    );
   };


export default App;