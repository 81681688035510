import React from "react";


function Login(){
    return(
        <div>
            login
        </div>
    )
}

export default Login;