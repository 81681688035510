import React, { Component, forwardRef, useState } from "react";
import Dialog from "./dialog";

const Item = (props) => {
  const [open, setOpen] = useState(false);
  // var fetchurl='https://www.caffedidar.ir/api_didar/photoitemtest/breakfast-english-normal.jpg'
  return (
    <div>
      <Dialog open={open} onclose={() => setOpen(false)}>
        <div className="flex flex-col  ">
            <img
              src={"https://www.caffedidar.ir/api_didar/Photos/" + props.photo}
              className="h-2/3 md:h-[500px] md:w-[500px] object-cover  rounded-t-lg"
              alt=""
            />
          <div className="h-1/3 flex flex-col gap-3  head-bgdialog">
            {/* title */}
            <div className="flex flex-row justify-between items-center mt-4">
              {/* mark food */}
              <div className=" items-center justify-center h-[2px] mx-2 w-full bg-[#ffca30]"></div>
              {/* title food */}
              <div className="flex flex-col w-max  leading-6">
                <span className="text-[1rem] text-center font-[700] text-[#0c498a]">
                  {props.name}
                </span>
                <span className="text-[0.93rem] text-center  font-[400] text-[#0c498a]">
                  {props.ename}
                </span>
              </div>
              {/* mark food */}

              <div className="items-center justify-center mx-4 h-[2px] w-full bg-[#ffca30]"></div>
            </div>
            {/* description */}
            <div className="flex flex-col mx-4 gap-3">
              {/* title description */}
              <div className="flex justify-end items-center gap-2">
                <span className="font-[700] text-[0.86rem]  text-[#0c498a]">
                  محتویات
                </span>
                <svg
                  width="15"
                  height="4"
                  viewBox="0 0 15 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.395908 2.08195L1.98633 0.86967L12.3559 0.894463L14.3773 2.09807L12.5942 3.17911L2.24917 3.21433L0.395908 2.08195Z"
                    fill="#FFCA30"
                  ></path>
                </svg>
              </div>
              <span className="font-[500] text-[0.86rem] flex justify-end text-[#0c498a]">
                {props.description}
              </span>
            </div>
            {/* price */}
            <div className="flex flex-row self-center w-fit items-center justify-center p-2 mb-5 border-b-2 text-[#0c498a] border-b-[#ffca30] ">
              <small className="text-[1.14rem] font-[600] mr-1 flex  justify-end items-end">
                <span>تومان</span>

                <span>هزار</span>
              </small>
              <div className=" text-[1.43rem] font-[900]">{props.price}</div>
            </div>
            <div className="bg-[#0c498a] m-2 flex justify-center py-3 rounded-lg cursor-pointer" onClick={()=>setOpen(false)}>
              <span className="text-white">بازگشت به منو</span>
            
            </div>
          </div>
        <div onClick={() => setOpen(false)}></div>

        </div>
      </Dialog>
      <div
        className="w-full rounded-[8px] h-[344px]  flex flex-col bg-white boxshadow cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <div className="h-2/3 ">
          {/* <img src={"./api_didar/Photos/"+props.photo} className=" h-[344px] w-full object-cover" alt="" /> */}
          <img
            src={"https://www.caffedidar.ir/api_didar/Photos/" + props.photo}
            className="h-full w-full object-cover  rounded-t-[8px] "
            alt=""
          />
        </div>
        <div className="w-[100%] h-1/3  relative">
          <div className="flex flex-col mt-2 ">
            <div className="flex flex-col px-3 items-center justify-center font-bold">
              <span className="text-[0.86rem] font-[400] text-[#0c498a]">
                {props.ename}
              </span>
              <span className="text-[0.93rem] font-[700] text-[#0c498a]">
                {props.name}
              </span>
            </div>
            {/* <div className="text-white text-[13px] mt-2 flex px-3 items-end justify-end ">
            {props.description}
          </div> */}

            <div className="absolute bottom-0 w-full px-2 text-[#0c498a] border-t-2 border-[#ffca30]">
              <div className="flex flex-row items-center justify-center p-2">
                <small className="text-[1.14rem] font-[600] mr-1 flex  justify-end items-end">
                  <span>تومان</span>

                  <span>هزار</span>
                </small>
                <div className=" text-[1.43rem] font-[900]">{props.price}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Item;
